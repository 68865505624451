import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import {
  MatButtonModule,
  MatIconModule,
  MatListModule,
  MatProgressSpinnerModule
} from "@angular/material";
import { FixtureTestStepComponentModule } from "../test-tools";
import { AutomaticTestComponentModule } from "../automatic-test";
import { ManualTestComponentModule } from "../manual-test";
import {
  FixtureTestComponent,
  DidNotFindGatewayComponent
} from "./fixture-test.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    FixtureTestStepComponentModule,
    AutomaticTestComponentModule,
    ManualTestComponentModule
  ],
  declarations: [DidNotFindGatewayComponent, FixtureTestComponent],
  entryComponents: [FixtureTestComponent]
})
export class FixtureTestComponentModule {}
