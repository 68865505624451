import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material";
import { CommonModule } from "@angular/common";
import { FixtureTestStepComponentModule } from "../test-tools";
import {
  ManualTestComponent,
  ManualRetryButtonComponent,
  ManualTestButtonsComponent
} from "./manual-test.component";

@NgModule({
  imports: [MatButtonModule, CommonModule, FixtureTestStepComponentModule],
  declarations: [
    ManualTestComponent,
    ManualRetryButtonComponent,
    ManualTestButtonsComponent
  ],
  exports: [ManualTestComponent]
})
export class ManualTestComponentModule {}
