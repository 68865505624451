import { Component } from "@angular/core";

@Component({
  selector: "fht-root",
  template: `
    <router-outlet></router-outlet>
  `,
  styles: []
})
export class AppComponent {}
