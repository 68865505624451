import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  Subscription,
  defer,
  EMPTY,
  timer,
  concat,
  BehaviorSubject
} from "rxjs";
import {
  repeat,
  catchError,
  distinctUntilChanged,
  switchMap,
  timeoutWith
} from "rxjs/operators";
import { Fixture, FixtureTestState } from "functions/src/models";
import { FixtureService } from "../fixture.service";

@Component({
  selector: "fht-manual-test-buttons",
  template: `
    <button
      [disabled]="pending"
      mat-stroked-button
      color="accent"
      (click)="onFail()"
    >
      No
    </button>
    <button
      [disabled]="pending"
      mat-stroked-button
      color="accent"
      (click)="onSucceed()"
    >
      Yes
    </button>
  `
})
export class ManualTestButtonsComponent {
  @Input() fixture: Fixture;
  pending: boolean = false;

  constructor(private fixtureService: FixtureService) {}

  onFail() {
    this.pending = true;

    this.fixtureService
      .markAsFailure(this.fixture.id)
      .subscribe(
        () => (this.pending = false),
        err => (console.error(err), (this.pending = false))
      );
  }

  onSucceed() {
    this.pending = true;

    this.fixtureService
      .markAsSuccess(this.fixture.id)
      .subscribe(
        () => (this.pending = false),
        err => (console.error(err), (this.pending = false))
      );
  }
}

@Component({
  selector: "fht-manual-retry-button",
  template: `
    <button [disabled]="pending" mat-stroked-button (click)="onRetry()">
      Retry Test
    </button>
  `
})
export class ManualRetryButtonComponent {
  @Input() fixture: Fixture;
  pending: boolean = false;

  constructor(private fixtureService: FixtureService) {}

  onRetry() {
    this.pending = true;

    this.fixtureService
      .retry(this.fixture.id)
      .subscribe(
        () => (this.pending = false),
        err => (console.error(err), (this.pending = false))
      );
  }
}

@Component({
  selector: "fht-manual-test",
  template: `
    <fht-fixture-test-step
      [fixture]="fixture"
      [state]="FixtureState.ManuallyTestingOn"
      title="The fixture should be blinking on and off. Is the fixture turning completely on?"
    >
      <fht-manual-test-buttons [fixture]="fixture"></fht-manual-test-buttons>
    </fht-fixture-test-step>
    <fht-fixture-test-error
      [fixture]="fixture"
      [state]="FixtureState.ManuallyTestingOnFailed"
    >
      The device did not turn on during testing
      <fht-manual-retry-button [fixture]="fixture"></fht-manual-retry-button>
    </fht-fixture-test-error>

    <fht-fixture-test-step
      [fixture]="fixture"
      [state]="FixtureState.ManuallyTestingOff"
      title="The fixture should be blinking on and off. Is the fixture turning completely off?"
    >
      <fht-manual-test-buttons [fixture]="fixture"></fht-manual-test-buttons>
    </fht-fixture-test-step>
    <fht-fixture-test-error
      [fixture]="fixture"
      [state]="FixtureState.ManuallyTestingOffFailed"
    >
      The device did not turn completely off during testing
      <fht-manual-retry-button [fixture]="fixture"></fht-manual-retry-button>
    </fht-fixture-test-error>

    <fht-fixture-test-step
      [fixture]="fixture"
      [state]="FixtureState.ManuallyTestingDim"
      title="The fixture should be blinking between dim and bright. Is the fixture dimming?"
    >
      <fht-manual-test-buttons [fixture]="fixture"></fht-manual-test-buttons>
    </fht-fixture-test-step>
    <fht-fixture-test-error
      [fixture]="fixture"
      [state]="FixtureState.ManuallyTestingDimFailed"
    >
      The device did not dim during testing
      <fht-manual-retry-button [fixture]="fixture"></fht-manual-retry-button>
    </fht-fixture-test-error>
  `
})
export class ManualTestComponent implements OnInit, OnDestroy {
  fixture$ = new BehaviorSubject<Fixture>({ id: "dne" } as any);
  @Input() set fixture(fixture: Fixture) {
    this.fixture$.next(fixture);
  }
  get fixture() {
    return this.fixture$.value;
  }

  FixtureState = FixtureTestState;
  sub: Subscription;

  constructor(private fixtureService: FixtureService) {}

  ngOnInit() {
    this.sub = this.fixture$
      .pipe(
        distinctUntilChanged((a, b) => a.state === b.state),
        switchMap(fixture => {
          switch (this.fixture.state) {
            case FixtureTestState.ManuallyTestingOn: {
              return this.toggleBetweenLevels(0, 100);
            }

            case FixtureTestState.ManuallyTestingOff: {
              return this.toggleBetweenLevels(100, 0);
            }

            case FixtureTestState.ManuallyTestingDim: {
              return this.toggleBetweenLevels(10, 100);
            }

            case FixtureTestState.Complete: {
              return this.dim(100);
            }

            default: {
              return EMPTY;
            }
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  dim(level: number) {
    return concat(
      timer(1500),
      this.fixtureService
        .dim(this.fixture.id, level)
        .pipe(
          catchError(err => {
            console.error(err);

            return EMPTY;
          })
        )
        .pipe(timeoutWith(4000, EMPTY))
    );
  }

  toggleBetweenLevels(lowLevel: number, highLevel: number) {
    let toggle = false;

    return defer(() => {
      if (toggle) {
        toggle = false;
        return this.dim(lowLevel);
      } else {
        toggle = true;
        return this.dim(highLevel);
      }
    }).pipe(repeat());
  }
}
