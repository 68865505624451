import { Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { map, switchMap, tap, distinctUntilChanged } from "rxjs/operators";
import { Observable, BehaviorSubject } from "rxjs";
import { Fixture, FixtureTestState, Gateway } from "functions/src/models";
import { FixtureService } from "../fixture.service";

@Component({
  selector: "fht-fixture-test",
  template: `
    <ng-container *ngIf="fixture$ | async as fixture">
      <fht-fixture-test-step [fixture]="fixture" [state]="FixtureState.Pending">
        Searching for a site controller to communicate with this fixture
      </fht-fixture-test-step>
      <fht-did-not-find-gateway [fixture]="fixture"></fht-did-not-find-gateway>

      <fht-automatic-test [fixture]="fixture"></fht-automatic-test>
      <fht-manual-test [fixture]="fixture"></fht-manual-test>

      <fht-fixture-test-success
        [fixture]="fixture"
        [state]="FixtureState.Complete"
      ></fht-fixture-test-success>
    </ng-container>
  `
})
export class FixtureTestComponent {
  fixture$: Observable<Fixture | undefined>;
  FixtureState = FixtureTestState;

  constructor(route: ActivatedRoute, fixtureService: FixtureService) {
    this.fixture$ = route.params.pipe(
      map(params => params.id as string),
      switchMap(id => fixtureService.getFixture(id)),
      tap(undefined, console.error)
    );
  }
}

@Component({
  selector: "fht-did-not-find-gateway",
  template: `
    <fht-fixture-test-error status="warn" [fixture]="fixture" [state]="state">
      Could not find a site controller to communicate with the fixture. This may
      indicate a connectivity problem. Contact customer support.
      <div class="actions" *ngIf="fixture.state === state">
        <button [disabled]="pending" mat-stroked-button (click)="onRetry()">
          Retry
        </button>
        <span>Or Select a Site Controller</span>
        <mat-list *ngIf="gateways$ | async as gateways; else loadingGateways">
          <button
            *ngFor="let gateway of gateways"
            [disabled]="pending"
            mat-list-item
            (click)="onSelectGateway(gateway)"
          >
            <mat-icon matListIcon>router</mat-icon>
            <p matLine>
              {{ gateway.name }}
            </p>
          </button>
        </mat-list>
        <ng-template #loadingGateways>
          <div class="loadingGateways" *ngIf="!gateways">
            <mat-progress-spinner
              color="primary"
              [diameter]="25"
              mode="indeterminate"
            ></mat-progress-spinner>
          </div>
        </ng-template>
      </div>
    </fht-fixture-test-error>
  `,
  styles: [
    `
      :host button[mat-stroked-button] {
        border-color: black;
      }

      .actions {
        display: flex;
        flex-direction: column;
        margin-top: 18px;
      }

      .actions span {
        position: relative;
        margin: 18px 0;
      }

      .actions span:before,
      .actions span:after {
        content: "";
        display: block;
        position: absolute;
        height: 1px;
        width: calc(50% - 105px);
        background-color: black;
        top: 50%;
      }

      .actions span:before {
        left: 0;
      }

      .actions span:after {
        right: 0;
      }

      :host mat-list {
        padding-top: 0 !important;
        border-radius: 3px;
        background-color: #131313;
        opacity: 0.85;
      }

      .loadingGateways {
        border-radius: 3px;
        padding: 16px;
        background-color: #131313;
        opacity: 0.85;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    `
  ]
})
export class DidNotFindGatewayComponent {
  fixture$ = new BehaviorSubject<Fixture>({ id: "dne" } as any);
  @Input() set fixture(fixture: Fixture) {
    this.fixture$.next(fixture);
  }
  get fixture() {
    return this.fixture$.value;
  }
  state = FixtureTestState.DidNotFindGateway;
  pending = false;

  gateways$ = this.fixture$.pipe(
    distinctUntilChanged((a, b) => a.id === b.id),
    switchMap(fixture =>
      this.fixtureService.getGatewaysAssignableToFixture(fixture.id)
    )
  );

  constructor(private fixtureService: FixtureService) {}

  onSelectGateway(gateway: Gateway) {
    this.pending = true;

    this.fixtureService
      .assignGatewayToFixture(this.fixture.id, gateway.id)
      .subscribe(
        () => (this.pending = false),
        err => (console.error(err), (this.pending = false))
      );
  }

  onRetry() {
    this.pending = true;

    this.fixtureService
      .retry(this.fixture.id)
      .subscribe(
        () => (this.pending = false),
        err => (console.error(err), (this.pending = false))
      );
  }
}
