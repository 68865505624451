import {
  Component,
  ViewChild,
  AfterViewInit,
  ElementRef,
  Output
} from "@angular/core";
import {
  interval,
  animationFrameScheduler,
  Observable,
  Observer,
  ReplaySubject
} from "rxjs";
import { map, switchMap, concatMap } from "rxjs/operators";
import QRCode from "qrcode-reader";

@Component({
  selector: "fht-qr-code-reader",
  template: `
    <h3>Scan Fixture QR Code</h3>
    <div class="videoWrapper">
      <video playsinline autoplay muted #videoRef></video>
      <div class="qrCodeHelper"></div>
    </div>
  `,
  styles: [
    `
      :host {
        padding: 20px 0;
      }

      .videoWrapper {
        position: relative;
      }

      video {
        width: 100%;
        height: auto;
      }

      .qrCodeHelper {
        width: 160px;
        height: 160px;
        position: absolute;
        border: 3px solid rgba(255, 255, 255, 0.5);
        top: 50%;
        margin-top: -80px;
        left: 50%;
        margin-left: -80px;
      }
    `
  ]
})
export class QRCodeReaderComponent implements AfterViewInit {
  @ViewChild("videoRef", { static: true }) videoRef: ElementRef<
    HTMLVideoElement
  >;
  ngAfterViewInit$ = new ReplaySubject(1);

  @Output() read = this.ngAfterViewInit$.pipe(
    concatMap(() =>
      navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: { ideal: "environment" }
        }
      })
    ),
    concatMap(stream => {
      const video = this.videoRef.nativeElement;
      const canvas = document.createElement("canvas");
      video.srcObject = stream;
      video.onloadedmetadata = () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
      };

      function readQRCode(imageData: ImageData) {
        return new Observable((observer: Observer<string>) => {
          const qr = new QRCode();

          qr.callback = function(err, value) {
            if (err) return observer.complete();

            observer.next(value.result);
            observer.complete();
          };

          qr.decode(imageData);
        });
      }

      return interval(17, animationFrameScheduler).pipe(
        map(() => {
          const ctx = canvas.getContext("2d");

          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

          return ctx.getImageData(0, 0, canvas.width, canvas.height);
        }),
        switchMap(imageData => readQRCode(imageData))
      );
    })
  );

  ngAfterViewInit() {
    this.ngAfterViewInit$.next(true);
  }

  ngOnDestroy() {
    const stream = this.videoRef.nativeElement.srcObject as MediaStream;

    if (!stream) return;

    stream.getTracks().forEach(track => track.stop());
  }
}
