import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  MatProgressSpinnerModule,
  MatIconModule,
  MatButtonModule
} from "@angular/material";
import { RouterModule } from "@angular/router";
import {
  FixtureTestStepComponent,
  FixtureTestErrorComponent,
  FixtureTestSuccessComponent,
  StatusComponent
} from "./fixture-test-step.component";

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    RouterModule
  ],
  declarations: [
    FixtureTestStepComponent,
    FixtureTestErrorComponent,
    FixtureTestSuccessComponent,
    StatusComponent
  ],
  exports: [
    FixtureTestStepComponent,
    FixtureTestErrorComponent,
    FixtureTestSuccessComponent,
    StatusComponent
  ]
})
export class FixtureTestStepComponentModule {}
