export enum PowerDataTypes {
  DIM_10_250 = "DIM-10-250",
  Phillips_DALI = "Phillips DALI"
}

export interface RuntimeConfig {
  activeSiteId: string;
}

export interface Site {
  id: string;
  api: string;
  name: string;
  testManually: boolean;
  controllerTypes: {
    [simplySnapType: string]: PowerDataTypes;
  };
}

export interface PowerData {
  voltage: number;
  current: number;
  power: number;
}

export interface Gateway {
  id: string;
  href: string;
  name: string;
  hostname: string;
  ipaddress: string;
  secretKey: string;
}

export enum FixtureTestActions {
  Succeed = "Succeed",
  Fail = "Fail",
  Retry = "Retry"
}

export enum FixtureTestState {
  /**
   * Base States
   */
  Pending = "Pending",
  DidNotFindGateway = "Did Not Find Gateway",
  Complete = "Complete",

  /**
   * For Automatic Testing
   */
  TestingAt0 = "[Auto] Testing at 0%",
  TestingAt0Failed = "[Auto] Testing at 0% Failed",
  TestingAt50 = "[Auto] Testing at 50%",
  TestingAt50Failed = "[Auto] Testing at 50% Failed",
  TestingAt100 = "[Auto] Testing at 100%",
  TestingAt100Failed = "[Auto] Testing at 100% Failed",

  /**
   * For Manual Testing
   */
  ManuallyTestingOn = "[Manual] Testing On",
  ManuallyTestingOnFailed = "[Manual] Testing On Failed",
  ManuallyTestingOff = "[Manual] Testing Off",
  ManuallyTestingOffFailed = "[Manual] Testing Off Failed",
  ManuallyTestingDim = "[Manual] Testing Dim",
  ManuallyTestingDimFailed = "[Manual] Testing Dim Failed"
}

export const INITIAL_AUTOMATIC_TESTING_STATE = FixtureTestState.TestingAt0;
export const INITIAL_MANUAL_TESTING_STATE = FixtureTestState.ManuallyTestingOn;

export type Fixture = {
  id: string;
  siteId: string;
  name: string;
  snapaddr: string;
  controllerType: string;
  powerDataType: PowerDataTypes;
  state: FixtureTestState;
  gatewayId?: string;
  powerData?: {
    0: PowerData;
    50: PowerData;
    100: PowerData;
  };
};

export type FixtureCreateParams = Pick<
  Fixture,
  "siteId" | "name" | "snapaddr" | "controllerType" | "powerDataType" | "state"
>;
