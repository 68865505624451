import { Component } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { FixtureService } from "../fixture.service";

@Component({
  selector: "fht-create-fixture-form",
  template: `
    <mat-toolbar>
      <span>Test Fixture</span>
    </mat-toolbar>

    <form *ngIf="!saving" (ngSubmit)="onSubmit()" [formGroup]="form">
      <mat-form-field>
        <input
          placeholder="Fixture Name"
          type="text"
          matInput
          formControlName="name"
        />
      </mat-form-field>
      <fht-qr-code-reader
        *ngIf="isReadingQRCode"
        (read)="onReadQRCode($event)"
      ></fht-qr-code-reader>
      <mat-form-field>
        <input
          placeholder="SNAP Address"
          type="text"
          matInput
          formControlName="snapaddr"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          placeholder="Controller Type"
          type="text"
          matInput
          formControlName="controllerType"
        />
      </mat-form-field>

      <button mat-raised-button color="accent" type="submit">
        Start Testing Fixture
      </button>
    </form>
  `,
  styles: [
    `
      form {
        display: flex;
        flex-direction: column;
        padding: 20px;
        max-width: 380px;
        margin: 0 auto;
      }
    `
  ]
})
export class CreateFixtureFormComponent {
  saving = false;
  isReadingQRCode = true;
  form = new FormGroup({
    name: new FormControl("", Validators.required),
    snapaddr: new FormControl("", Validators.required),
    controllerType: new FormControl("", Validators.required)
  });

  constructor(private fixtureService: FixtureService, private router: Router) {}

  onReadQRCode($event: string) {
    const [version, snapaddr, productionDate, controllerType] = $event.split(
      ":"
    );

    if (!snapaddr || !controllerType) return;

    this.isReadingQRCode = false;
    this.form.patchValue({ snapaddr, controllerType });
  }

  onSubmit() {
    if (!this.form.valid) return;

    this.saving = true;

    this.fixtureService.createFixture(this.form.value).subscribe(
      response => {
        this.router.navigateByUrl(`/fixture/${response.id}`);
      },
      err => {
        console.error(err);

        this.saving = false;
      }
    );
  }
}
