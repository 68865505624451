import { Component, Input, HostBinding } from "@angular/core";
import { Fixture, FixtureTestState } from "functions/src/models";

@Component({
  selector: "fht-status",
  template: `
    <ng-content></ng-content>
  `,
  styles: [
    `
      :host {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 60px;
      }

      :host(.error) {
        background-color: #e74c3c;
      }

      :host(.complete) {
        background-color: #27ae60;
      }

      :host(.warn) {
        background-color: #f39c12;
        color: black;
      }

      :host /deep/ h1 {
        margin: 40px 0 0;
        padding: 0 40px;
        text-align: center;
      }

      :host /deep/ p {
        text-align: center;
        padding: 0 40px;
      }
    `
  ]
})
export class StatusComponent {
  @HostBinding("class") @Input() status:
    | "pending"
    | "error"
    | "complete"
    | "warn";
}

@Component({
  selector: "fht-fixture-test-step",
  template: `
    <fht-status status="pending" *ngIf="fixture.state === state">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate"
      ></mat-progress-spinner>
      <h1>
        <ng-container *ngIf="title; else defaultTitle">{{
          title
        }}</ng-container>
        <ng-template #defaultTitle>Testing {{ fixture.name }}</ng-template>
      </h1>
      <p><ng-content></ng-content></p>
    </fht-status>
  `,
  styles: [``]
})
export class FixtureTestStepComponent {
  @Input() fixture: Fixture;
  @Input() state: FixtureTestState;
  @Input() title: string | undefined;
}

@Component({
  selector: "fht-fixture-test-error",
  template: `
    <fht-status [status]="status" *ngIf="fixture.state === state">
      <div class="matIconWrapper">
        <mat-icon inline>error</mat-icon>
      </div>
      <h1>Error Testing {{ fixture.name }}</h1>
      <p><ng-content></ng-content></p>
    </fht-status>
  `,
  styles: [
    `
      .matIconWrapper {
        font-size: 128px;
        margin-bottom: -40px;
      }
    `
  ]
})
export class FixtureTestErrorComponent {
  @Input() fixture: Fixture;
  @Input() state: FixtureTestState;
  @Input() status: "error" | "warn" = "error";
}

@Component({
  selector: "fht-fixture-test-success",
  template: `
    <fht-status status="complete" *ngIf="fixture.state === state">
      <div class="matIconWrapper">
        <mat-icon inline>check_circle</mat-icon>
      </div>
      <h1>Success!</h1>
      <p>Fixture {{ fixture.name }} appears to be working correctly</p>
      <a routerLink="/" mat-stroked-button>Test Another Fixture</a>
    </fht-status>
  `,
  styles: [
    `
      .matIconWrapper {
        font-size: 128px;
        margin-bottom: -40px;
      }
    `
  ]
})
export class FixtureTestSuccessComponent {
  @Input() fixture: Fixture;
  @Input() state: FixtureTestState;
}
