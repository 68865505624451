import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { Fixture, Gateway } from "functions/src/models";
import { map } from "rxjs/operators";

const BASE_URL =
  "https://us-central1-fixture-healh-test.cloudfunctions.net/api";

@Injectable({ providedIn: "root" })
export class FixtureService {
  constructor(private http: HttpClient, private firestore: AngularFirestore) {}

  getFixture(fixtureId: string): Observable<Fixture> {
    return this.firestore
      .doc<Fixture>(`/fixtures/${fixtureId}`)
      .valueChanges()
      .pipe(map(data => ({ ...data, id: fixtureId })));
  }

  createFixture(options: {
    name: string;
    snapaddr: string;
    controllerType: string;
  }) {
    return this.http.post<{ id: string }>(`${BASE_URL}/fixture`, options);
  }

  dim(fixtureId: string, level: number) {
    return this.http.patch<{ status: "ok" }>(
      `${BASE_URL}/fixture/${fixtureId}/level`,
      {
        level
      }
    );
  }

  markAsSuccess(fixtureId: string) {
    return this.http.patch<{ status: "ok" }>(
      `${BASE_URL}/fixture/${fixtureId}/succeed`,
      {}
    );
  }

  markAsFailure(fixtureId: string) {
    return this.http.patch<{ status: "ok" }>(
      `${BASE_URL}/fixture/${fixtureId}/fail`,
      {}
    );
  }

  retry(fixtureId: string) {
    return this.http.patch<{ status: "ok" }>(
      `${BASE_URL}/fixture/${fixtureId}/retry`,
      {}
    );
  }

  getGatewaysAssignableToFixture(fixtureId: string) {
    return this.http.get<Gateway[]>(
      `${BASE_URL}/fixture/${fixtureId}/gateways`
    );
  }

  assignGatewayToFixture(fixtureId: string, gatewayId: string) {
    return this.http.patch<{ status: "ok" }>(
      `${BASE_URL}/fixture/${fixtureId}/gateway`,
      { gatewayId }
    );
  }
}
