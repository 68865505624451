import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  MatInputModule,
  MatButtonModule,
  MatToolbarModule
} from "@angular/material";
import { ReactiveFormsModule } from "@angular/forms";
import { CreateFixtureFormComponent } from "./create-fixture-form.component";
import { QRCodeReaderComponent } from "./qr-code-reader.component";

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatToolbarModule,
    ReactiveFormsModule
  ],
  declarations: [CreateFixtureFormComponent, QRCodeReaderComponent],
  entryComponents: [CreateFixtureFormComponent]
})
export class CreateFixtureFormComponentModule {}
