import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireModule } from "@angular/fire";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFireModule.initializeApp({
      apiKey: "AIzaSyBHuDI_lNkc77W635mtet5lf3PfpyBkBQY",
      authDomain: "fixture-healh-test.firebaseapp.com",
      databaseURL: "https://fixture-healh-test.firebaseio.com",
      projectId: "fixture-healh-test",
      storageBucket: "fixture-healh-test.appspot.com",
      messagingSenderId: "130155614319",
      appId: "1:130155614319:web:7d4f7be7e6af72f5"
    }),
    AngularFirestoreModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
