import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import {
  CreateFixtureFormComponentModule,
  CreateFixtureFormComponent
} from "./create-fixture-form";
import {
  FixtureTestComponent,
  FixtureTestComponentModule
} from "./fixture-test";

const routes: Routes = [
  {
    path: "",
    component: CreateFixtureFormComponent
  },
  {
    path: "fixture/:id",
    component: FixtureTestComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    CreateFixtureFormComponentModule,
    FixtureTestComponentModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
