import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FixtureTestStepComponentModule } from "../test-tools";
import { AutomaticTestComponent } from "./automatic-test.component";

@NgModule({
  imports: [CommonModule, FixtureTestStepComponentModule],
  declarations: [AutomaticTestComponent],
  exports: [AutomaticTestComponent]
})
export class AutomaticTestComponentModule {}
