import { Component, Input } from "@angular/core";
import { Fixture, FixtureTestState } from "functions/src/models";

@Component({
  selector: "fht-automatic-test",
  template: `
    <fht-fixture-test-step
      [fixture]="fixture"
      [state]="FixtureState.TestingAt0"
    >
      Testing fixture at 0% dim
    </fht-fixture-test-step>
    <fht-fixture-test-error
      [fixture]="fixture"
      [state]="FixtureState.TestingAt0Failed"
    >
      Power level of the fixture indicates the device is not turning off
      correctly
    </fht-fixture-test-error>
    <fht-fixture-test-step
      [fixture]="fixture"
      [state]="FixtureState.TestingAt50"
    >
      Testing fixture at 50% dim
    </fht-fixture-test-step>
    <fht-fixture-test-error
      [fixture]="fixture"
      [state]="FixtureState.TestingAt50Failed"
    >
      Power level of the fixture indicates the device is not dimming correctly
    </fht-fixture-test-error>
    <fht-fixture-test-step
      [fixture]="fixture"
      [state]="FixtureState.TestingAt100"
    >
      Testing fixture at 100% dim
    </fht-fixture-test-step>
    <fht-fixture-test-error
      [fixture]="fixture"
      [state]="FixtureState.TestingAt100Failed"
    >
      Power level of the fixture indicates the device is not turning on
      correctly
    </fht-fixture-test-error>
  `
})
export class AutomaticTestComponent {
  @Input() fixture: Fixture;
  FixtureState = FixtureTestState;
}
